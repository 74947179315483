/* RegisterPage.css */
.register-page {
    background-image: url('../public/11.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Added padding for better spacing on smaller screens */
}

.register-container {
    max-width: 400px;
    width: 100%; /* Ensure it takes full width on smaller screens */
    margin: 50px auto;
    padding: 30px; /* Increased padding for a more spacious feel */
    border: none; /* Removed border for a cleaner look */
    border-radius: 12px; /* Increased border radius for smoother corners */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Stronger shadow for depth */
    background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent background for a subtle effect */
    backdrop-filter: blur(10px); /* Adds a blur effect behind the container */
}

.register-container h1 {
    text-align: center;
    margin-bottom: 25px; /* Increased space below the heading */
    color: #444; /* Darker color for better contrast */
    font-family: 'Roboto', sans-serif; /* Modern font for better readability */
    font-weight: 500;
}

.register-container form {
    display: flex;
    flex-direction: column;
}

.register-container input {
    margin-bottom: 15px;
    padding: 12px; /* Slightly larger padding for better touch targets */
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease; /* Smooth transition for border color */
}

.register-container input:focus {
    border-color: #007bff; /* Highlighted border color on focus */
    outline: none; /* Remove default outline */
}

.register-container button {
    padding: 12px; /* Larger padding for a more substantial button */
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.register-container button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

.register-container button:disabled {
    background-color: #999;
    cursor: not-allowed; /* Change cursor to indicate non-interaction */
}

.register-container p {
    margin-top: 15px; /* Increased space above the paragraph */
    font-size: 14px;
    color: #d9534f;
    text-align: center;
}

.register-container p a {
    color: #007bff; /* Matching link color with the button */
    text-decoration: none;
}

.register-container p a:hover {
    text-decoration: underline; /* Underline on hover for better usability */
}
