.App {
    font-family: Arial, sans-serif;
    text-align: center;
    padding-bottom: 60px;
    position: relative; /* Ensure relative positioning for absolute elements */
}

nav {
    margin: 20px 0;
}

nav .button-container{
    margin: 0px 0;
}

.welcome-message {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

nav .button-container {
    display: inline-block;
    position: relative; /* Create a positioning context for the background */
    padding: 10px; /* Add padding to ensure content is not too close to edges */
    border-radius: 8px; /* Rounded corners for the background area */
    width: 2000px; /* Control the width of the container */
    height: 50px; /* Control the height of the container */
    overflow: hidden; /* Ensure that the image does not exceed container bounds */
}


nav .button-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Ensure the background covers the entire width */
    height: 100%; /* Ensure the background covers the entire height */
    background-image: url('../public/11.jpg'); /* Replace with your image path */
    background-size: contain; /* Control the size of the image, `contain` ensures it fits within the container */
    background-position: center; /* Center the image within the container */
    opacity: 0.5; /* Adjust opacity as needed */
    z-index: -1; /* Ensure the background is behind the buttons */
    border-radius: 8px; /* Match the border radius for a cohesive look */
}

nav button {
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s;
    position: relative; /* Ensure buttons are positioned relative to their container */
    z-index: 1; /* Ensure buttons appear above the background image */
}

nav button:hover {
    background-color: #0056b3;
}

nav button.active {
    background-color: orange; /* Active button color */
    color: black;
}

.text-container {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin-top: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    white-space: pre-wrap; /* Preserve whitespace and line breaks */
    height: 400px; /* Fixed height */
    overflow-y: scroll; /* Scrollable if content overflows */
}

textarea, select {
    width: 100%;
    margin-top: 10px;
}

input[type="text"], input[type="date"], textarea {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin: 10px 0;
}
