.SectionsTable {
    padding: 20px;
    max-width: 100%;
    overflow: hidden;
}

.table-container {
    max-height: 600px; /* Set the max height for the table container */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: auto; /* Enable horizontal scrolling if needed */
    border: 1px solid #ddd; /* Optional: Add a border */
    
}

table {
    width: 100%;
    border-collapse: collapse;
}

thead th {
    position: sticky;
    top: 0;
    background-color: #f2f2f2; /* Ensure a background color is set */
    z-index: 1; /* Ensures the header stays on top of the content */
    border: 1px solid #ddd; /* Optional: Add a border */
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

tr:hover {
    background-color: #f1f1f1;
}

.scrollable-text {
    max-height: 100px; /* Set the desired max height */
    overflow-y: auto; /* Enable vertical scrolling */
    padding-right: 10px; /* Optional: Add some padding for aesthetic spacing */
}

.pagination {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.pagination button {
    padding: 8px 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination span {
    font-size: 16px;
    font-weight: bold;
}
