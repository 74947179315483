/* Footer.css */
.footer {
    background-color: #333;
    color: white;
    padding: 20px;
    text-align: center;
    position: fixed;
    width: 100%;
    bottom: 0;
}

.footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.footer-links li {
    display: inline;
}

.footer-links a {
    color: white;
    text-decoration: none;
    font-size: 14px;
}

.footer-links a:hover {
    text-decoration: underline;
}

.footer-copyright {
    margin-top: 10px;
    font-size: 14px;
}
