/* AnimatedBanner.css */
.animated-banner {
    background-color: #4CAF50; /* Change the color as needed */
    color: white;
    padding: 20px;
    text-align: center;
    animation: slideDown 1s ease-out;
}

.banner-content h1 {
    font-size: 36px;
    margin: 0;
    animation: fadeIn 2s ease-in;
}

.banner-content p {
    font-size: 18px;
    margin: 10px 0 0;
    animation: fadeIn 2.5s ease-in;
}

/* Slide down animation */
@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

/* Fade in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
