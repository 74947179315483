/* SearchPage.css */

.SearchPage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px; /* Adjust the max-width as needed */
    margin-top: 20px;
}

.search-form {
    width: 30%; /* Adjust the width as needed */
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.results-container {
    width: 65%; /* Adjust the width as needed */
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    max-height: 80vh; /* Adjust this value as needed to fit your layout */
}

.result-box {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.result-people,
.result-company {
    margin-top: 10px;
}

.scrollable-text {
    max-height: 150px; /* Adjust as needed */
    overflow-y: auto;
    margin-top: 10px;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.6;
}

.highlight {
    background-color: yellow;
}

button {
    padding: 10px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

button:hover {
    background-color: #45a049;
}

input, select {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #ccc;
}

h1 {
    margin-bottom: 20px;
}

h3 {
    margin-top: 0;
}


